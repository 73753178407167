import React from "react";
import { Container } from "react-bootstrap";
// import { Swiper, SwiperSlide } from "swiper/react";
import getImagePath from "helpers/get-image-path";
import Link from "components/link";

import {
  hero, heroMainImage, heroContent, heroContentTitle,
  heroContentBtn, imageOverlay
} from "./style.mod.scss";

const HeroSection = ({ data }) => {

  const { heroImage, buttonText, buttonUrl, heroTitle, overlay } = data;

  return (
    <div className={hero}>
      <Container>
        <div className={heroContent}>
          { (heroTitle) && <h2 className={heroContentTitle}>{heroTitle}</h2> }
          { (buttonUrl) && <Link to={buttonUrl} className={heroContentBtn}>{buttonText}</Link> }
        </div>
      </Container>

      { (overlay) && <div className={imageOverlay}></div> }

      <div className={heroMainImage}>
        <img srcSet={getImagePath(heroImage, true)} alt={heroImage?.alt} />
      </div>
    </div>
  );
};

export default HeroSection;
